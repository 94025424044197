import './tournamentDetail.css'
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getTournamentById } from "../../../services/tournament.service";
import { getTeamByName } from "../../../services/team.service";
import { getPlayersByTeam, getPlayerByToken } from "../../../services/player.service"
import TeamItem from "./../../teams/item/TeamItem";
import PlayerItem from "./../../profiles/item/playerItem";
import Comments from '../../comments/Comments';
import { constants } from "../../utils/const";
import Chat from '../../chats/Chat';
import { getMaps } from "./../../../services/map.service"

export default function TournamentDetail({ }) {
  const [idTorneo, setIdTorneo] = useState([]);
  const [color, setColor] = useState([]);
  const [colorFecha, setColorFecha] = useState([]);
  const [colorFechaInicio, setColorFechaInicio] = useState([]);
  const [allowJoin, setAllowJoin] = useState(false);
  const [fotoMapa, setfotoMapa] = useState([]);
  const [mapa, setMapa] = useState([]);
  const [maxteams, setMaxteams] = useState([]);
  const [nombre, setNombre] = useState([]);
  const [partida, setPartida] = useState([]);
  const [numPartidas, setNumPartidas] = useState([]);
  const [reglas, setReglas] = useState([]);
  const [teams, setTeams] = useState([]);
  const [details, setDetails] = useState([]);
  const [dealerCode, setDealerCode] = useState([]);
  const [organizadores, setOrganizadores] = useState([]);
  const [isOrganizador, setIsOrganizador] = useState(false);
  const [finInscripcion, setFinInscripcion] = useState([]);
  const [fechaComienzo, setFechaComienzo] = useState([]);
  const [equiposApuntados, setEquiposApuntados] = useState([]);
  const [isFinished, setIsFinished] = useState(false);
  const [tieneNick, setTieneNick] = useState(false);

  let { id } = useParams();

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));
    const nick = JSON.parse(localStorage.getItem("nick"));
    if (nick === constants.defaultNick) {
      setTieneNick(false)
    } else {
      setTieneNick(true)
    }
    fetchTeams(id, token);
  }, []);
  const fetchTeamsPlayer = async (token, organizadores) => {
    try {
      const player = await getPlayerByToken(token);
      for (let organizador of organizadores) {
        if (organizador.email === player.email) {
          setIsOrganizador(true);
          break;
        } else setIsOrganizador(false);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const fetchTeams = async (id, token) => {
    try {
      const mapsDB = await getMaps();
      const tournament = await getTournamentById(id);
      for (let map of mapsDB) {
        if (tournament.mapa === map.name) {
          tournament.url = map.url;
        }
      }

      await fetchTeamsJoints(tournament.teams, tournament.maxteams);
      setfotoMapa(`/${tournament.url}`);
      setMapa(tournament.mapa);
      setMaxteams(tournament.maxteams);
      setNombre(tournament.nombre);
      setReglas(tournament.reglas);
      setPartida(tournament.partida);
      setNumPartidas(tournament.numPartidas)
      setTeams(tournament.teams)
      setDetails(tournament.details)
      setDealerCode(tournament.dealerCode);
      setOrganizadores(tournament.organizadores)
      setFinInscripcion(new Date(tournament.finInscripcion).toLocaleString())
      setFechaComienzo(new Date(tournament.fechaComienzo).toLocaleString())
      checkColorFecha(tournament.finInscripcion);
      checkColorFechaInicio(tournament.fechaComienzo)
      setIdTorneo(tournament._id)
      setIsFinished(tournament.isFinished);
      await fetchTeamsPlayer(token, tournament.organizadores)
    } catch (e) {
      console.log(e);
    }
  };
  const fetchTeamsJoints = async (equiposApuntadosDB, Maxteams) => {
    try {
      let equipos = [];
      for (let equipoApuntadoDB of equiposApuntadosDB) { //Recorro los equipos apuntados
        let jugadoresCompletosDB = [];
        const team = await getTeamByName(equipoApuntadoDB.name);
        const playersDB = await getPlayersByTeam(equipoApuntadoDB.name); //Me traigo los jugadors completos del equipo

        for (let playerApuntado of equipoApuntadoDB.players) { //Recorro los jugadores del equipo apuntado
          for (let playerDB of playersDB) {
            if (playerApuntado === playerDB.email) { //Si coinciden los emails.. añado el jugador completo
              jugadoresCompletosDB.push(playerDB)
            }
          }
        }
        team.players = jugadoresCompletosDB;
        equipos.push(team)
      }
      setEquiposApuntados(equipos)
      colorTeams(equipos, Maxteams);
    } catch (e) {
      console.log(e);
    }
  };
  const colorTeams = (equipos, Maxteams) => {
    if ((equipos.length >= 0)) {
      setColor("#0AE800"); //Verde
    }
    if ((equipos.length >= Maxteams * 0.5)) {
      setColor("#0074E8"); //Azul
    }
    if ((equipos.length >= Maxteams * 0.75)) {
      setColor("#E81C00"); //Rojo
    }
  }
  const checkColorFecha = (fechaInscripcion) => {
    const actual = new Date();
    const fechaElegida = new Date(fechaInscripcion);
    if (fechaElegida.getTime() <= actual.getTime()) {
      setColorFecha("#E81C00"); //Rojo
      setAllowJoin(false)
    } else {
      setColorFecha("#0AE800"); //Verde
      setAllowJoin(true)
    }
  }
  const checkColorFechaInicio = (fechaComienzo) => {
    const actual = new Date();
    const fechaElegida = new Date(fechaComienzo);
    if (fechaElegida.getTime() <= actual.getTime()) {
      setColorFechaInicio("#E81C00"); //Rojo
    } else {
      setColorFechaInicio("#0AE800"); //Verde
    }
  }
  const unirse = async () => {
    {
      !tieneNick
        ? window.location = '/popup/login'
        : window.location = `/popup/dealer/${idTorneo}/a0000`
    }
  }
  const copy = async (e) => {
    await navigator.clipboard.writeText(`${process.env.REACT_APP_FRONT}/popup/dealer/${idTorneo}/${dealerCode}`);
    e.target.classList.add("copied");
  }
  return (
    <div className='tournament_detail_section'>
      <div className='list_section'>
        <aside className="asideLeft">

        </aside>
        <div className='tournament_detail_grid border_section'>
          <div className="tournament_detail">
            {!isFinished
              ? <div>{isOrganizador &&
                <div className='tournament_detail_match'>
                  <p><b>Eres uno de los organizadores de este torneo.</b></p>
                  <p>¡Puedes añadir un <u>resultado</u> y/o un <u>organizador</u> pulsando aquí debajo!</p>
                  <div className='tournament_detail_options'>
                    <Link to={`/matches/newMatch/${idTorneo}`} ><button className='btn_section btn_gold'>+ Resultado</button></Link>
                    <div className='tournament_detail_copy'>
                      <h5>Compartir enlace:</h5>
                      <img className="team_detail_nav_copy_link" src="/clipboard.png" alt="copylink" width={20} onClick={copy} onTouchStart={copy} />
                      <h5 className='gold'>{dealerCode}</h5>
                    </div>
                  </div>
                </div>
              }</div>
              : <div>
                <div className='tournament_detail_winner'>
                  <br /><h1>¡Finalizado!</h1><br />
                  <TeamItem position={1} team={equiposApuntados[0]} teamTournament={teams[0]} />
                  <img className="tournament_detail_logovictoria" src={`/medallon.png`} alt="" />
                </div>
                <br /></div>
            }
            <div className="tournament_detail_container">
              <h2 className='permanent gold'>{nombre}</h2>
              <img src={fotoMapa} alt={mapa} className="fotomapa_detail" />
              <h4 className='permanent'>{mapa}</h4>
            </div><br />
            {!isFinished && <Link to={`/tournaments/join/${id}`} ><button className='btn_section btn_gold' onClick={unirse}>Ser Organizador</button></Link>}
            <br />
            <div className='tournament_detail_date'>
              <h4>Inscripción hasta:</h4>
              <h5 style={{ color: colorFecha }}>{finInscripcion}</h5>
            </div>
            <div className='tournament_detail_date'>
              <h4>Inicio del Torneo:</h4>
              <h5 style={{ color: colorFechaInicio }}>{fechaComienzo}</h5>
            </div>
            <div className='tournament_detail_date'>
              <h4>Tipo de Partida:</h4>
              <h5 className="text">{partida}</h5>
            </div>
            <div className='tournament_detail_date'>
              <h4>Número de partidas:</h4>
              <h5 className="text">{numPartidas}</h5>
            </div><br />
            <div className="tournament_detail_nav">
              <Link to={`/matches/${idTorneo}`}><button className='btn_section btn_green'>Resultados</button></Link>
              {allowJoin && !isFinished
                ? <Link to={`/tournaments/join/${id}`} ><button className='btn_section btn_green'>Unirme</button></Link>
                : <div><button className='btn_section btn_red' >Unirme</button></div>
              }
            </div><br />
            <div className="tournamentdetail_teams">
              <div className="num-teams">
                <h4>Equipos:</h4>
                <h3 style={{ color: color }}><b>{equiposApuntados.length}:{maxteams}</b></h3>
              </div>
              {equiposApuntados.map((team, i) => {
                return <div><br />
                  <TeamItem key={i} position={i + 1} team={team} teamTournament={teams[i]} />
                  <h5>&nbsp;&nbsp;&nbsp;-Asistentes:</h5>
                  {team.players.map((jugador, i) => {
                    return <div className='tournamentdetail_player'>
                      <PlayerItem key={i} position={i + 1} jugador={jugador} />
                    </div>
                  })}
                </div>
              })}

            </div>
            <div className="tournamentdetail_row">
              <h4>Organizadores:</h4>
              {organizadores.map((organizador, i) => {
                return <h5 className="text">&nbsp;&nbsp;&nbsp;- {organizador.nick} -</h5>
              })}
            </div><br />
            <div className="tournamentdetail_row">
              <h4>Reglas:</h4>
              <h5 className="text">&nbsp;&nbsp;&nbsp;-Por cada Muerte: {reglas.pointsBaja} PUNTOS</h5>
              <h5 className="text">&nbsp;&nbsp;&nbsp;-Equipo termina 1º :&nbsp;&nbsp;&nbsp;  x{reglas.primero} </h5>
              <h5 className="text">&nbsp;&nbsp;&nbsp;-Equipo termina 2º :&nbsp;&nbsp;&nbsp;  x{reglas.segundo} </h5>
              <h5 className="text">&nbsp;&nbsp;&nbsp;-Equipo termina 3º :&nbsp;&nbsp;&nbsp;  x{reglas.tercero} </h5>
            </div><br />
            <div className="tournamentdetail_row">
              <h4>Detalles del Torneo:</h4>
              <h5 className="text">&nbsp;&nbsp;&nbsp;-{details}</h5>
            </div>
          </div>
          <Comments />
        </div>
        <aside className="asideRight">
          <Chat />
        </aside>
      </div>
    </div>
  );
}

